<template>
    <imagine-layout title="Registre mandats" class="mandates">
        <template v-slot:actions>
            <imagine-icon-button @click="redirectTo('mandateAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
        </template>

        <div class="imagine-secondary-zone mandates__filters">
            <div class="mandates__filters__filter">
                <imagine-select label="Type"
                                :choices="references.mandateTypeChoices()"
                                name="type"
                                v-model="typeFilter"/>
            </div>
            <div class="mandates__filters__filter">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Nom propriétaire"
                               name="actor"
                               v-model="actorFilter"/>
            </div>
            <div class="mandates__filters__filter">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Résidence/Lotissement"
                               name="property"
                               v-model="propertyFilter"/>
            </div>
            <div class="mandates__filters__filter mandates__filters__filter--bordered">
                <span>Voir les archives</span>
                <imagine-switch v-model="withArchivesFilter"/>
            </div>
        </div>

        <div :class="{'mandates__count--filtered': nbFiltered < nbTotal}"
             class="mandates__count">
            <div class="mandates__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">{{ formatNumber(nbFiltered) }} / </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                mandat{{ nbFiltered > 1 ? 's' : '' }}
            </div>
            <div v-show="nbTotal > 0 && nbFiltered <= 0">
                Aucun mandat trouvé.
            </div>

            <div class="mandates__count__sort"
                 v-if="mandates.length > 0">
                Triés par numéro croissant
            </div>
        </div>

        <div class="imagine-table-container"
             v-if="filtered.length > 0">
            <table class="imagine-table">
                <thead>
                <tr class="imagine-table__row">
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--number">
                        N°
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                        Date
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header">Propriétaire(s)</th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                        Bien(s)
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                        Date fin
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                        Observations
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr :class="{'imagine-table__row--disabled': isArchive(mandate)}"
                    @click="redirectTo('mandate', {id: mandate.id})"
                    class="imagine-table__row mandates__mandate"
                    v-for="mandate in filtered">
                    <td class="imagine-table__row__cell imagine-table__row__cell--number">
                        #{{ mandate.registryNumber }}
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--date">
                        {{ formatDate(mandate.startDate) }}
                    </td>
                    <td class="imagine-table__row__cell">
                        {{ mandate.owners.map(owner => owner.shortName).join(', ') }}
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--text">
                        <span v-if="mandate.properties.length === 1">
                            {{ mandate.properties[0].infos }}
                        </span>
                        <ul class="mandates__mandate__properties"
                            v-else-if="mandate.properties.length > 1">
                            <li v-for="property in mandate.properties">
                                {{ property.infos }}
                            </li>
                        </ul>
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--date">
                        <span v-if="mandate.endDate">{{ formatDate(mandate.endDate) }}</span>
                        <span v-else-if="mandate.legalEndDate">({{ formatDate(mandate.legalEndDate) }})</span>
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--text">{{ mandate.observation }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mandates__none"
             v-else>
            Aucun mandat.
        </div>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSwitch from '../components/ImagineSwitch.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineSelectPeriod from '../components/ImagineSelectPeriod.vue';
import ImagineButton from '../components/ImagineButton.vue';
import {mapState} from 'vuex';
import ImagineSelect from "../components/ImagineSelect";

const _queryMatchContact = (contact, query) => {
    if (contact) {
        return (contact.lastname && contact.lastname.toUpperCase().search(query.toUpperCase()) !== -1)
            || (contact.company && contact.company.toUpperCase().search(query.toUpperCase()) !== -1);
    }

    return false;
};

const _queryMatchProperty = (property, query) => {
    const upperQuery = query.toUpperCase();

    return (property.reference && property.reference.startsWith(upperQuery))
        || (property.infos && property.infos.toUpperCase().includes(upperQuery));
};

export default {
    components: {
        ImagineSelect,
        ImagineButton,
        ImagineSelectPeriod,
        ImagineLayout,
        ImagineIconButton,
        ImagineInput,
        ImagineSwitch
    },
    computed: {
        ...mapState(['references']),
        ...mapState('mandate', {mandates: state => state.all}),
        typeFilter: {
            get() {
                return this.$store.state.mandate.typeFilter;
            },
            set(value) {
                this.$store.commit('mandate/setTypeFilter', value);
            }
        },
        actorFilter: {
            get() {
                return this.$store.state.mandate.actorFilter;
            },
            set(value) {
                this.$store.commit('mandate/setActorFilter', value);
            }
        },
        propertyFilter: {
            get() {
                return this.$store.state.mandate.propertyFilter;
            },
            set(value) {
                this.$store.commit('mandate/setPropertyFilter', value);
            }
        },
        withArchivesFilter: {
            get() {
                return this.$store.state.mandate.withArchivesFilter;
            },
            set(value) {
                this.$store.commit('mandate/setWithArchivesFilter', value);
            }
        },
        filtered() {
            return this.mandates
                .filter(mandate => this.typeFilter ? mandate.type === this.typeFilter : true)
                .filter(mandate => this.propertyFilter ? this._propertiesMatch(mandate, this.propertyFilter) : true)
                .filter(mandate => this.actorFilter ? this._actorsMatch(mandate, this.actorFilter) : true)
                .filter(mandate => !this.withArchivesFilter ? !this.isArchive(mandate) : true);
        },
        nbFiltered() {
            return this.filtered.length;
        },
        nbTotal() {
            return this.mandates.length;
        }
    },
    created() {
        this.load('Chargement des mandats');
        Promise.all([
            this.$store.dispatch('mandate/reload'),
            this.$store.dispatch('reloadReferences')
        ])
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        isArchive(mandate) {
            return mandate.endDate && !this.$container.types.isInFuture(mandate.endDate);
        },
        formatDate(date) {
            return this.$container.types.formatDate(date);
        },
        _actorsMatch(mandate, query) {
            if (!query) {
                return true;
            }

            return mandate.owners.some(owner => _queryMatchContact(owner, query));
        },
        _propertiesMatch(mandate, query) {
            if (!query) {
                return true;
            }

            return mandate.properties.some(property => _queryMatchProperty(property, query));
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandates {
    &__mass-modal {
        .imagine-button {
            width: 100%;
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.mandates__count--filtered {
            .mandates__count__count {
                font-weight: 500;
            }
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }

    &__mandate {
        cursor: pointer;

        &__properties {
            padding: 0;
            margin: 0;
            list-style-position: inside;
        }
    }

    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -8px;
            margin-left: -8px;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
                @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                    flex: unset;
                    width: 150px;
                }
            }

            .md-field {
                margin: 0;
            }
        }
    }
}
</style>
