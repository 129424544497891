import container from '../container';

const repo = container.repos.mandate;

export default {
    namespaced: true,
    state: {
        all: [],
        periods: [],
        typeFilter: 'sale',
        actorFilter: null,
        propertyFilter: null,
        withArchivesFilter: false,
        registry: null,
        actors: [],
    },
    mutations: {
        setTypeFilter(state, typeFilter) {
            state.typeFilter = typeFilter;
        },
        setActorFilter(state, actorFilter) {
            state.actorFilter = actorFilter;
        },
        setPropertyFilter(state, propertyFilter) {
            state.propertyFilter = propertyFilter;
        },
        setWithArchivesFilter(state, withArchives) {
            state.withArchivesFilter = withArchives;
        }
    },
    actions: {
        reloadRegistry(context, type) {
            return repo.registry(type)
                .then(registry => context.state.registry = registry);
        },
        reload(context) {
            return repo.all().then(mandates => context.state.all = mandates);
        },
        touchAll({state, dispatch}) {
            if (!state.all || state.all.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        save(context, data) {
            return repo.save(data);
        },
        one(context, id) {
            return repo.one(id);
        },
        remove(context, id) {
            return repo.remove(id);
        }
    }
};
