<template>
    <imagine-layout :inner="true" class="mandate" :title="title">
        <template v-slot:actions>
            <imagine-icon-button @click="showRemoveConfirmation = true"
                                 class="mdc-top-app-bar__navigation-icon"
                                 :disabled="mandate.sale || hasLeases"
                                 icon="delete"
                                 v-if="mandate"/>
        </template>

        <imagine-tab-bar :tabs="tabs"
                         v-if="tabs.length > 1"
                         @tab="activeTabId = $event"/>

        <div v-if="activeTabId === 'infos'">
            <div class="imagine-container">
                <form @submit.prevent="save"
                      autocomplete="off"
                      class="imagine-form">

                    <imagine-alert type="info"
                                   v-if="!mandateId">
                        Avant de commencer à saisir un nouveau mandat, il est nécessaire de
                        créer ou de vérifier <a @click.prevent="redirectTo('propertyAdd')" href="#">le(s) bien(s)</a> et
                        <a href="#" @click.prevent="redirectTo('contactAdd')">le(s) propriétaire(s)</a>.
                    </imagine-alert>

                    <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

                    <div class="imagine-form__row imagine-form__row--limited">
                        <imagine-select :choices="references.mandateTypeChoices()"
                                        :required="true"
                                        class="imagine-form__row__field"
                                        label="Type"
                                        name="type"
                                        v-model="type"/>

                        <imagine-date-picker :required="true"
                                             class="imagine-form__row__field"
                                             label="Date de signature"
                                             name="date"
                                             v-model="date"/>

                        <imagine-select :choices="salesRepChoices"
                                        class="imagine-form__row__field"
                                        label="Négociateur"
                                        name="salesrep"
                                        v-model="salesRep"/>

                        <div class="imagine-form__row__field mandate__isexclusive">
                            <imagine-switch v-model="isExclusive">
                                <span>Exclusivité ?</span>
                            </imagine-switch>
                        </div>
                    </div>

                    <div class="imagine-form__row imagine-form__row--limited">
                        <imagine-input :required="true"
                                       class="imagine-form__row__field"
                                       label="Numéro d'ordre"
                                       name="registryNumber"
                                       :violation="errors.registryNumber"
                                       v-model="registryNumber"/>

                        <imagine-date-picker :required="true"
                                             class="imagine-form__row__field"
                                             label="Date de début"
                                             name="startDate"
                                             :min-date="minMandateDate"
                                             v-model="startDate"/>

                        <imagine-date-picker v-model="endDate"
                                             class="imagine-form__row__field"
                                             label="Date de fin"
                                             :help="legalEndDate ? 'Légalement ' + formatDate(legalEndDate) : ''"
                                             name="endDate"/>
                    </div>

                    <div class="imagine-form__row"
                         v-for="(ownerSlot, slot) in ownerSlots"
                         v-if="nbOwners >= slot">
                        <imagine-contact-autocomplete
                            :filter="canContactBeOwner"
                            :label="slot > 0 ? 'Autre propriétaire' : ('Propriétaire' +  (nbOwners >= 1 ? ' principal' : ''))"
                            :required="slot <= 0"
                            @input="removeOwnerSlotHoles()"
                            class="imagine-form__row__field"
                            name="owner"
                            v-model="ownerSlot.owner"/>
                    </div>

                    <div class="imagine-form__row"
                         v-for="(propertySlot, slot) in propertySlots"
                         v-if="nbProperties >= slot">
                        <imagine-property-autocomplete :filter="canPropertyReceiveMandate"
                                                       :label="slot > 0 ? 'Autre bien' : 'Bien'"
                                                       :required="slot <= 0"
                                                       :violation="slot <= 0 ? errors.properties : ''"
                                                       @input="removePropertySlotHoles()"
                                                       class="imagine-form__row__field"
                                                       name="property"
                                                       v-model="propertySlot.property"/>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-textarea class="imagine-form__row__field"
                                          label="Observations"
                                          name="comment"
                                          v-model="comment"/>
                    </div>

                    <div class="imagine-form__actions">
                        <imagine-button @click.prevent="back()">Annuler</imagine-button>
                        <imagine-button :primary="true"
                                        type="submit">
                            Sauvegarder
                        </imagine-button>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="mandate && activeTabId === 'leases'">
            <div class="imagine-container">
                <div class="mandate-leases">
                    <div v-if="hasLeases">
                        <template v-for="property in mandate.properties">
                            <h3 class="mandate-leases__title">{{ property.infos }}</h3>

                            <div class="imagine-table-container">
                                <table class="imagine-table"
                                       v-if="leases(property).length > 0">
                                    <thead>
                                    <tr class="imagine-table__row">
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                            Locataire(s)
                                        </th>
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                                            Date d'entrée
                                        </th>
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                                            Date de sortie
                                        </th>
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--rent">
                                            Loyer charges comprises
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :class="{'imagine-table__row--disabled': lease.end && (lease.end < $container.types.today())}"
                                        class="imagine-table__row mandate-leases__tenant"
                                        v-for="lease in leases(property)">
                                        <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                            {{ lease.tenants.map(tenant => tenant.shortName).join(', ') }}
                                        </td>
                                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                                            {{ formatDate(lease.start) }}
                                        </td>
                                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                                            {{ formatDate(lease.end) }}
                                        </td>
                                        <td class="imagine-table__row__cell imagine-table__row__cell--rent">
                                            {{ formatMoney(lease.rent) }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="mandate-leases__none"
                                     v-else>
                                    Aucun locataire pour ce bien.
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="mandate-leases__none"
                         v-else>
                        Aucun bail.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTabId === 'sale'">
            <div class="imagine-container">
                <div class="mandate-sale">
                    <div v-if="mandate && mandate.sales.length > 0">
                        <template v-for="property in mandate.properties">
                            <h3 class="mandate-sale__title">{{ property.infos }}</h3>

                            <p v-if="sale(property)">
                                Acheteur : {{ sale(property).buyer.shortName }}
                            </p>
                            <p class="mandate-sale__none" v-else>
                                Aucune vente pour ce bien.
                            </p>
                        </template>
                    </div>
                    <div class="mandate-sale__none" v-else>
                        Aucune vente.
                    </div>
                </div>
            </div>
        </div>

        <imagine-modal @act="removeMandate()"
                       @close="showRemoveConfirmation = false"
                       button-label="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer ce mandat ?</p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineSwitch from '../components/ImagineSwitch.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImaginePropertyAutocomplete from '../components/ImaginePropertyAutocomplete.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import {mapState} from 'vuex';

const MAX_OWNER_PER_LEASE = 100;
const MAX_PROPERTIES_PER_MANDATE = 300;

const reserveSlots = (nbSlots) => {
    let slots = [];

    for (let i = 0; i < nbSlots; i++) {
        slots.push({property: null, owner: null});
    }

    return slots;
};

export default {
    components: {
        ImagineFileInput,
        ImagineIconButton,
        ImagineContactAutocomplete,
        ImaginePropertyAutocomplete,
        ImagineLayout,
        ImagineInput,
        ImagineButton,
        ImagineDatePicker,
        ImagineAlert,
        ImagineTextarea,
        ImagineTabBar,
        ImagineModal,
        ImagineSelect,
        ImagineSwitch
    },
    computed: {
        ...mapState('contact', {contacts: state => state.displayed}),
        ...mapState('employee', {employees: state => state.all}),
        ...mapState('property', {properties: state => state.displayed}),
        ...mapState('mandate', {registry: state => state.registry, defaultType: state => state.typeFilter}),
        ...mapState(['references']),
        title() {
            if (this.mandateId) {
                if (this.registryNumber) {
                    return 'Mandat #' + this.registryNumber;
                }

                return 'Mandat';
            }

            if (this.registryNumber) {
                return 'Ajout mandat #' + this.registryNumber;
            }

            return 'Ajout mandat';
        },
        propertyIds() {
            return this.propertySlots.filter(propertySlot => propertySlot.property).map(propertySlot => propertySlot.property.id);
        },
        nbProperties() {
            return this.propertyIds.length;
        },
        ownerIds() {
            return this.ownerSlots.filter(ownerSlot => ownerSlot.owner).map(ownerSlot => ownerSlot.owner.id);
        },
        nbOwners() {
            return this.ownerIds.length;
        },
        tabs() {
            let tabs = [{id: 'infos', title: 'Infos', active: this.activeTabId === 'infos'}];

            if (this.mandate) {
                if (this.mandate.type === 'sale') {
                    tabs.push({id: 'sale', title: 'Vente', active: this.activeTabId === 'sale'});
                } else {
                    tabs.push({id: 'leases', title: 'Locations', active: this.activeTabId === 'leases'});
                }
            }

            return tabs;
        },
        hasLeases() {
            if (!this.mandate) {
                return false;
            }

            return this.mandate.leases.length > 0;
        },
        legalEndDate() {
            if (!this.mandate || !this.startDate || this.endDate) {
                return null;
            }

            return this.mandate.legalEndDate;
        },
        salesRepChoices() {
            return this.references.salesRepChoices(this.employees);
        },
        typeChoices() {
            return this.references.mandateTypeChoices();
        }
    },
    watch: {
        type(newVal) {
            if (!this.registryNumber) {
                this.$store.dispatch('mandate/reloadRegistry', newVal)
                    .then(() => this._resetRegistry());
            }
            this.$store.commit('mandate/setTypeFilter', newVal);
        }
    },
    data() {
        return {
            activeTabId: 'infos',
            mandateId: null,
            mandate: null,
            date: this.$container.types.today(),
            registryNumber: null,
            minMandateDate: null,
            startDate: this.$container.types.today(),
            endDate: null,
            salesRep: null,
            isExclusive: false,
            type: 'sale',
            owner: null,
            ownerSlots: reserveSlots(MAX_OWNER_PER_LEASE),
            comment: null,
            errors: {},
            propertySlots: reserveSlots(MAX_PROPERTIES_PER_MANDATE),
            showRemoveConfirmation: false
        }
    },
    created() {
        this.type = this.defaultType;
        this.mandateId = this.$route.params.id;

        this.load();
        Promise.all([
            this.$store.dispatch('employee/touch'),
            this.$store.dispatch('touchReferences'),
            this.$store.dispatch('contact/touch'),
            this.$store.dispatch('property/touch'),
            this.$store.dispatch('mandate/reloadRegistry', this.type)
        ])
            .then(() => {
                if (!this.mandateId) {
                    const forContactId = this.$route.query ? parseInt(this.$route.query.contactId) : null;

                    if (forContactId) {
                        this.owner = this.contacts.find(contact => contact.id === parseInt(forContactId));
                        this.ownerSlots[0].owner = this.owner;
                    }

                    const forPropertyId = this.$route.query ? parseInt(this.$route.query.propertyId) : null;

                    if (forPropertyId) {
                        this.property = this.properties.find(property => property.id === parseInt(forPropertyId));
                        this.propertySlots[0].property = this.property;
                    }

                    this._resetRegistry();
                    this.unload();
                } else {
                    this._reloadMandate();
                }
            })
            .catch(error => {
                this.serverError(error);
                this.unload();
            });
    },
    methods: {
        save() {
            this.errors = {};
            this.load();
            this.$store.dispatch('mandate/save', {
                id: this.mandateId,
                isExclusive: this.isExclusive,
                type: this.type,
                salesRep: this.salesRep,
                registryNumber: this.registryNumber,
                date: this.date,
                startDate: this.startDate,
                endDate: this.endDate,
                owners: this.ownerIds,
                propertyIds: this.propertyIds,
                comment: this.comment
            })
                .then(mandate => {
                    const isCreated = !this.mandateId;
                    this.mandateId = mandate.id;

                    if (isCreated) {
                        this.unload();

                        return this.redirectTo('mandates');
                    }

                    this._remapMandate(mandate);
                    this.unload();
                    this.success('Mandat sauvegardé.');
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                });
        },
        removeMandate() {
            this.load();
            this.$store.dispatch('mandate/remove', this.mandateId)
                .then(() => {
                    this.showRemoveConfirmation = false;
                    this.redirectTo('mandates', {}, () => this.success('Mandat supprimé.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        removePropertySlotHoles() {
            setTimeout(() => {
                const nbSlots = this.propertySlots.length;
                const nonEmptySlots = this.propertySlots.filter(propertySlot => propertySlot.property);
                this.propertySlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        },
        removeOwnerSlotHoles() {
            setTimeout(() => {
                const nbSlots = this.ownerSlots.length;
                const nonEmptySlots = this.ownerSlots.filter(ownerSlot => ownerSlot.owner);
                this.ownerSlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        },
        leases(property) {
            if (!this.mandate) {
                return [];
            }

            return this.mandate.leases.filter(lease => lease.propertyId === property.id);
        },
        sale(property) {
            if (!this.mandate) {
                return null;
            }

            return this.mandate.sales.find(sale => sale.propertyId === property.id);
        },
        canPropertyReceiveMandate(property) {
            return !property.canBeNewBuildPurchased && !property.hasActiveMandates && !this.propertyIds.includes(property.id);
        },
        canContactBeOwner(contact) {
            return !this.ownerIds.includes(contact.id);
        },
        refresh() {
            this.load();
            this._reloadMandate()
                .catch(this.serverError)
                .finally(this.unload);
        },
        _resetRegistry() {
            this.registryNumber = this.registry.lastNumber + 1;
            this.minMandateDate = this.registry.lastDate;
        },
        _reloadMandate() {
            return this.$store.dispatch('mandate/one', this.mandateId)
                .then(mandate => this._remapMandate(mandate))
                .catch(this.serverError)
                .finally(this.unload);
        },
        _remapMandate(mandate) {
            this.mandate = mandate;
            this.isExclusive = mandate.isExclusive;
            this.type = mandate.type;
            this.salesRep = mandate.salesRepId;
            this.date = mandate.date;
            this.registryNumber = mandate.registryNumber;
            this.startDate = mandate.startDate;
            this.endDate = mandate.endDate;
            this.comment = mandate.comment;
            mandate.owners.forEach((owner, index) => {
                this.ownerSlots[index].owner = owner;
            });
            mandate.properties.forEach((property, index) => {
                this.propertySlots[index].property = property;
            });
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate {
    &__readonly {
        flex: 1;
        font-size: 1rem;

        .mdc-list {
            padding: 0;
        }
    }

    &__isexclusive {
        @media (min-width: map-get($imagine-breakpoints, 'phone')) {
            text-align: right;
            flex: 0 0 150px;
            margin-bottom: 1.5rem;
        }
    }
}

.mandate-leases {
    &__title {
        font-weight: normal;
        font-size: 1.2rem;
        margin: 2rem .5rem .5rem;
    }

    &__tenant {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}

.mandate-sale {
    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
