<template>
    <div class="properties">
        <transition name="slideY">
            <div class="imagine-secondary-zone search__filters" v-if="mode === 'search'">
                <div class="search__filters__search">
                    <imagine-input :autocomplete="false"
                                   :clearable="true"
                                   :required="false"
                                   icon="search"
                                   label="Référence / mot clé"
                                   name="query"
                                   v-model="query"/>
                </div>
            </div>
        </transition>

        <transition name="slideY">
            <div class="imagine-secondary-zone properties__filters" v-if="mode !== 'search'">
                <div class="properties__filters__filter">
                    <imagine-select :choices="transactionChoices"
                                    label="Transaction"
                                    name="transaction"
                                    v-model="transaction"/>
                </div>
                <div class="properties__filters__filter">
                    <imagine-select :choices="references.groupedBlockChoices(blocks)"
                                    label="Résidence imagine"
                                    name="block"
                                    v-model="block"/>
                </div>
                <div class="properties__filters__filter">
                    <imagine-select :choices="typeChoices"
                                    label="Type"
                                    name="type"
                                    v-model="type"/>
                </div>
                <div class="properties__filters__filter">
                    <imagine-select :choices="nbRoomsChoices"
                                    label="Nb. pièces min."
                                    name="nbRooms"
                                    v-model="nbRooms"/>
                </div>
                <div class="properties__filters__filter" v-if="transaction">
                    <imagine-select :choices="availableChoices"
                                    label="Disponibilité"
                                    name="available"
                                    v-model="available"/>
                </div>
            </div>
        </transition>

        <div :class="{'properties__count--filtered': nbFiltered < nbTotal}"
             class="properties__count">
            <div class="properties__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">
                    {{ formatNumber(nbFiltered) }} /
                </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                bien{{ nbFiltered > 1 ? 's' : '' }}
            </div>
            <div v-show="nbFiltered <= 0">
                Aucun bien trouvé.
            </div>

            <div class="properties__count__sort"
                 v-if="mode !== 'map'"
                 v-show="nbFiltered > 1">
              <imagine-select :choices="sortChoices"
                              class="properties__count__sort__select"
                              implicit-required
                              label="Tri"
                              name="sort"
                              v-model="sort"/>
            </div>
            <div v-else>
                Avec position seult.
            </div>
        </div>

        <slot name="header"/>

        <div class="properties__map" v-if="mode === 'map'">
            <a href="#" @click.prevent="showCadastre = !showCadastre" style="text-align: right;display: block;margin: 1rem 0;">
                {{ showCadastre ? 'Retour carte classique' : 'Voir tefenua/cadastre (expérimental)' }}
            </a>
            <imagine-cadastre :is-shown="mode === 'map'" :places="displayedPlaces" v-if="showCadastre"/>
            <imagine-map :is-shown="mode === 'map'" :places="displayedPlaces" v-else/>
        </div>

        <div class="mdc-list mdc-list--two-line" v-else>
            <template v-for="property in displayed">
                <slot v-bind:property="property"/>
                <div class="mdc-list-divider"></div>
            </template>

            <div @click="displayMore()"
                 class="properties__see-more"
                 v-show="isDisplayLimited">
                <imagine-button>Voir plus de biens</imagine-button>
            </div>
        </div>
    </div>
</template>

<script>
import ImagineIconButton from '../../components/ImagineIconButton.vue';
import ImagineButton from '../../components/ImagineButton.vue';
import ImagineSelect from '../../components/ImagineSelect.vue';
import ImagineSwitch from '../../components/ImagineSwitch.vue';
import ImagineMap from '../../components/ImagineMap.vue';
import ImagineInput from '../../components/ImagineInput.vue';
import ImagineCadastre from '../../components/ImagineCadastre.vue';
import {mapState} from 'vuex';
import debounce from "lodash-es/debounce";

const MAX_NB_ROOMS = 10;

export default {
    props: {
        mode: {type: String, default: null},
        prefilter: {type: String, default: null}
    },
    components: {
        ImagineCadastre, ImagineInput, ImagineSwitch, ImagineIconButton, ImagineButton, ImagineSelect, ImagineMap},
    computed: {
        ...mapState('property', {
            displayed: state => state.displayed,
            nbFiltered: state => state.nbFiltered,
            nbTotal: state => state.nbTotal,
            maxDisplayed: state => state.maxDisplayed,
            markers: state => state.markers
        }),
        ...mapState('block', {blocks: state => state.all}),
        ...mapState(['references']),
        transactionChoices() {
            const choices = this.references.propertyTransactionChoices();
            return this.prefilter === 'agency' ? choices.filter(choice => choice.value !== 'new_build') : choices;
        },
        typeChoices() {
            let choices = this.references.propertyTypeChoices();

            if (this.isNewBuild || this.block) {
                choices.forEach(choice => {
                    if (!this.references.imaginePropertyTypes().includes(choice.value)) {
                        choice.disabled = true;
                    }
                });
            }

            return choices;
        },
        nbRoomsChoices() {
            let choices = [];
            for (let i = 1; i <= MAX_NB_ROOMS; i++) {
                choices.push(this.makeChoice(i));
            }
            return choices;
        },
        availableChoices() {
            return [
                {label: this.isNewBuild || this.isOld ? 'Sans acquéreur' : 'Vacant',  value: 'yes', disabled: false},
            ];
        },
        displayedPlaces() {
            return this._makeBlockPlaces(this.markers.filter(marker => marker.type === 'block'))
                .concat(this._makePropertyPlaces(this.markers.filter(marker => marker.type === 'property')));
        },
        isDisplayLimited() {
            if (this.displayed.length <= 0) {
                return false;
            }

            return this.displayed.length < this.nbFiltered;
        },
        isNewBuild() {
            return this.transaction === 'new_build';
        },
        isOld() {
            return this.transaction === 'old';
        },
        query: {
            get() {
                return this.$store.state.property.query;
            },
            set(value) {
                this.$store.commit('property/setQuery', value);
            }
        },
        transaction: {
            get() {
                return this.$store.state.property.transaction;
            },
            set(value) {
                this.$store.commit('property/setTransaction', value);
            }
        },
        type: {
            get() {
                return this.$store.state.property.type;
            },
            set(value) {
                this.$store.commit('property/setType', value);
            }
        },
        block: {
            get() {
                return this.$store.state.property.block;
            },
            set(value) {
                this.$store.commit('property/setBlock', value);
            }
        },
        nbRooms: {
            get() {
                return this.$store.state.property.nbRooms;
            },
            set(value) {
                this.$store.commit('property/setNbRooms', value);
            }
        },
        available: {
            get() {
                return this.$store.state.property.available;
            },
            set(value) {
                this.$store.commit('property/setAvailable', value);
            }
        },
        sort: {
            get() {
                return this.$store.state.property.sort;
            },
            set(value) {
                this.$store.commit('property/setSort', value);
            }
        },
        sortChoices() {
            return [
                {value: 'updated_desc', label: 'Le plus récent', disabled: false},
                {value: 'name_asc', label: 'Ordre alphabétique', disabled: false}
            ];
        }
    },
    data() {
        return {
            search: null,
            showCadastre: false
        }
    },
    created() {
        this.search = debounce(this._search, 200);
    },
    watch: {
        query() {
            this.search();
        },
        transaction() {
            this._search();
        },
        type() {
            this._search();
        },
        block() {
            this._search();
        },
        nbRooms() {
            this._search();
        },
        available() {
            this._search();
        },
        sort() {
            this._search();
        }
    },
    methods: {
        displayMore() {
            this.load();
            this.$store.dispatch('property/displayMore')
                .catch(this.serverError)
                .finally(this.unload);
        },
        _search() {
            this.load();
            this._searchDispatch()
                .catch(this.serverError)
                .finally(this.unload)
        },
        _searchDispatch() {
            if (this.mode === 'map') {
                return Promise.all([
                    this.$store.dispatch('property/reloadMarkers'),
                    this.$store.dispatch('property/reload')
                ]);
            }

            return this.$store.dispatch('property/reload');
        },
        _makeBlockPlaces(markers) {
            return markers
                .map(marker => {
                    return {
                        icon: 'business',
                        position: marker.position,
                        description: marker.description,
                        redirectHandler: () => this.redirectTo('block', {id: marker.id})
                    }
                });
        },
        _makePropertyPlaces(markers) {
            return markers
                .map(marker => {
                    return {
                        icon: 'home_work',
                        position: marker.position,
                        description: marker.description,
                        redirectHandler: () => this.redirectTo('property', {id: marker.id})
                    }
                });
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/config';

.properties {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
            }

            .md-field {
                margin: 0;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.properties__count--filtered {
            .properties__count__count {
                font-weight: 500;
            }
        }

        &__sort {
            &__select {
                background: transparent;
            }
        }
    }

    &__see-more {
        cursor: pointer;
        text-align: center;
        padding: 1rem;
    }

    &__map {
        padding: .5rem 1rem;

        .imagine-map {
            height: 65vh;
        }
    }
}
</style>
