<template>
    <imagine-layout title="Biens agence">
        <template v-slot:actions>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :active="mode === 'search'"
                                 @click="changeMode('search')"
                                 icon="search"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 @click="changeMode('list')"
                                 :active="mode === 'list'"
                                 icon="filter_list"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 @click="changeMode('map')"
                                 :active="mode === 'map'"
                                 icon="map"/>
            <imagine-icon-button @click="redirectTo('propertyAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
        </template>

        <properties-list :mode="mode" prefilter="agency">
            <template v-slot:default="{property}">
                <div @click="gotoProperty(property)"
                     class="mdc-list-item">
                    <span class="mdc-list-item__text">
                        <span class="mdc-list-item__primary-text">
                            {{ property.infos }}
                        </span>
                        <span class="mdc-list-item__secondary-text">
                            Référence {{ property.reference }}
                        </span>
                    </span>
                    <span class="mdc-list-item__meta">
                        {{ property.status }}
                    </span>
                </div>
            </template>
        </properties-list>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import PropertiesList from './elements/PropertiesList.vue';
import {mapState} from 'vuex';

export default {
    components: {PropertiesList, ImagineLayout, ImagineIconButton},
    computed: {
        ...mapState('property', {mode: state => state.mode})
    },
    created() {
        this.$store.commit('property/setPrefilter', 'agency');
        this.load('Chargement des biens');

        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        changeMode(newMode) {
            if (newMode === 'map') {
                this.load('Chargement de la carte');
                this.$store.dispatch('property/reloadMarkers')
                    .then(() => this.$store.commit('property/changeMode', newMode))
                    .catch(this.serverError)
                    .finally(this.unload);
            } else {
                this.$store.commit('property/changeMode', newMode);
            }
        },
        gotoProperty(property) {
            this.$store.commit('property/resetLastTab');
            this.redirectTo('property', {id: property.id})
        },
        _getDependencies() {
            return [
                this.$store.dispatch('property/reload'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch')
            ];
        }
    }
}
</script>
