class PropertyRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all(params) {
        return this._http.get('/api/property', params);
    }

    markers(params) {
        return this._http.get('/api/property/map-marker', params);
    }

    published() {
        return this._http.get('/api/property/published');
    }

    one(id) {
        return this._http.get('/api/property/' + id)
            .then(property => this._makeProperty(property));
    }

    save(data) {
        return this._http.post('/api/property' + (data.id ? '/' + data.id : ''), data)
            .then(property => this._makeProperty(property));
    }

    addEvent(propertyId, description) {
        return this._http.post('/api/property/' + propertyId + '/event', {description})
            .then(event => this._makeEvent(event));
    }

    remove(id) {
        return this._http.delete('/api/property/' + id);
    }

    duplicate(id) {
        return this._http.post('/api/property/' + id + '/duplicate');
    }

    publish(id) {
        return this._http.post('/api/property/' + id + '/publication');
    }

    unpublish(id) {
        return this._http.delete('/api/property/' + id + '/publication');
    }

    book(id, contactId, type) {
        return this._http.post('/api/property/' + id + '/transaction', {contactId, type});
    }

    _makeProperty(property) {
        property.rent = this._types.stringToMoney(property.rent);
        property.charges = this._types.stringToMoney(property.charges);
        property.water = this._types.stringToMoney(property.water);
        property.waste = this._types.stringToMoney(property.waste);
        property.price = this._types.stringToMoney(property.price);
        property.sale = property.sale ? this._makeSale(property.sale) : null;
        property.mandates = property.mandates.map(mandate => this._makeMandate(mandate));
        property.taxExemptionEnd = this._types.stringToDate(property.taxExemptionEnd);
        property.timeline = property.timeline.map(event => this._makeEvent(event));

        return property;
    }

    _makeMandate(mandate) {
        mandate.startDate = this._types.stringToDate(mandate.startDate);

        return mandate;
    }

    _makeSale(sale) {
        sale.certificateDate = this._types.stringToDate(sale.certificateDate);

        return sale;
    }

    _makeEvent(event) {
        event.date = this._types.stringToDate(event.date);

        return event;
    }
}

export default PropertyRepository;
