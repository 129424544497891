<template>
    <imagine-layout title="Annonces" class="publishing">
        <template v-slot:actions>
            <imagine-icon-button :active="mode === 'published'"
                                 class="mdc-top-app-bar__action-item"
                                 @click="changeMode('published')"
                                 icon="list"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :active="mode === 'search'"
                                 @click="changeMode('search')"
                                 icon="search"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 @click="changeMode('list')"
                                 :active="mode === 'list'"
                                 icon="filter_list"/>
            <imagine-icon-button :active="false"
                                 @click="externalRedirectTo('/api/web.xml')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="public"/>
        </template>

        <div v-if="mode === 'published'">
            <template v-if="published.length > 0">
                <p class="publishing__disclaimer">
                    Les mises à jour sont assurées par immobilier.pf et le site web à intervalles réguliers.
                </p>

                <div class="publishing__count">
                    <div class="publishing__count__count">
                        <span>{{ formatNumber(published.length) }}</span>
                        annonce{{ published.length > 1 ? 's' : '' }}
                    </div>
                    <div class="publishing__count__sort">Ordre alphabétique</div>
                </div>
                <div class="mdc-list mdc-list--two-line">
                    <template v-for="property in published">
                        <div @click.self="gotoProperty(property)"
                             class="mdc-list-item">
                            <span @click="gotoProperty(property)"
                                  class="mdc-list-item__text">
                                <span class="mdc-list-item__primary-text">
                                    {{ property.infos }}
                                </span>
                                <span class="mdc-list-item__secondary-text">
                                    Référence {{ property.reference }}
                                </span>
                            </span>
                            <span class="mdc-list-item__meta">
                                <imagine-button @click="unpublish(property)">Dépublier</imagine-button>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>
            </template>
            <div class="publishing__none" v-else>
                Aucune annonce publiée.
            </div>
        </div>
        <template v-else>
            <properties-list :mode="mode" prefilter="agency">
                <template v-slot:default="{property}">
                    <div @click.self="gotoProperty(property)"
                         class="mdc-list-item">
                        <span @click="gotoProperty(property)"
                              class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">
                                {{ property.infos }}
                            </span>
                            <span class="mdc-list-item__secondary-text">
                                Référence {{ property.reference }}
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">
                            <template v-if="!property.isPublished">
                                <imagine-button
                                    @click="publish(property)"
                                    :primary="true"
                                    v-if="property.isPublishable">
                                    Publier
                                </imagine-button>
                                <template v-else>
                                    <imagine-button primary disabled>Publier</imagine-button>
                                    <md-tooltip md-direction="left">Seules les annonces possédant un code postal et un prix ou un loyer sont publiables.</md-tooltip>
                                </template>
                            </template>
                            <imagine-button @click="unpublish(property)" v-else>
                                Dépublier
                            </imagine-button>
                        </span>
                    </div>
                </template>
            </properties-list>
        </template>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import PropertiesList from './elements/PropertiesList.vue';
import ImagineCheckbox from '../components/ImagineCheckbox.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import {mapState} from 'vuex';

export default {
    components: {
        ImagineButton,
        ImagineCheckbox,
        ImagineLayout,
        PropertiesList,
        ImagineIconButton
    },
    computed: {
        ...mapState('property', {
            nbTotal: state => state.nbTotal,
            nbFiltered: state => state.nbFiltered,
            published: state => state.published,
            mode: state => state.mode
        })
    },
    data() {
        return {
            publishing: null,
            showConfirmationModal: false
        }
    },
    created() {
        this.$store.commit('property/setPrefilter', 'agency');
        this.changeMode('published');
        this.load('Chargement');
        Promise.all([
            this.$store.dispatch('reloadReferences'),
            this.$store.dispatch('property/touch'),
            this.$store.dispatch('block/touch'),
            this.$store.dispatch('property/reloadPublished')
        ])
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        unpublish(property) {
            this.publishing = property.id;
            this.$store.dispatch('property/unpublish', this.publishing)
                .then(() => {
                    Promise.all([
                        this.$store.dispatch('property/reloadPublished'),
                        this.$store.dispatch('property/reload')
                    ])
                        .then(() => this.success('Dépublication OK.'))
                        .catch(this.serverError)
                        .finally(() => this.publishing = null);
                })
                .catch(e => {
                    this.serverError(e);
                    this.publishing = null;
                });
        },
        publish(property) {
            this.publishing = property.id;
            this.$store.dispatch('property/publish', this.publishing)
                .then(() => {
                    Promise.all([
                        this.$store.dispatch('property/reloadPublished'),
                        this.$store.dispatch('property/reload')
                    ])
                        .then(() => this.success('Publication OK.'))
                        .catch(this.serverError)
                        .finally(() => this.publishing = null);
                })
                .catch(e => {
                    this.serverError(e);
                    this.publishing = null;
                });
        },
        gotoProperty(property) {
            this.$store.commit('property/resetLastTab');
            this.redirectTo('property', {id: property.id})
        },
        changeMode(newMode) {
            this.$store.commit('property/changeMode', newMode);
        }
    }
}
</script>

<style lang="scss">
.publishing {
    .imagine-layout__header {
        .publishing__actions-sep {
            border-right: 1px solid white;
            height: 24px;
            margin: 0 .5rem;
        }

        .mdc-icon-button:disabled {
            opacity: .45;
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }

    &__disclaimer {
        padding: 1rem;
        font-style: italic;
        margin: 0;
    }

    &__count {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }

    &__header {
        padding: .5rem 16px .5rem 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__select {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .md-menu {
                margin-left: -10px;
            }

            &__menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__count {
                padding: 0 .5rem;
            }
        }

    }
}
</style>
