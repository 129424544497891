class ContactRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all(params) {
        return this._http.get('/api/contact', params);
    }

    emailableIds(params) {
        return this._http.get('/api/contact/emailable/id', params);
    }

    emailableRecipients(ids) {
        return this._http.get('/api/contact/emailable/preview', {ids});
    }

    one(id) {
        return this._http.get('/api/contact/' + id)
            .then(contact => this._makeContact(contact));
    }

    save(data) {
        return this._http.post('/api/contact' + (data.id ? '/' + data.id : ''), data)
            .then(contact => this._makeContact(contact));
    }

    addEvent(contactId, description) {
        return this._http.post('/api/contact/' + contactId + '/event', {description})
            .then(event => this._makeEvent(event));
    }

    addToFavorites(contactId) {
        return this._http.post('/api/contact/' + contactId + '/favorite');
    }

    removeFromFavorites(contactId) {
        return this._http.delete('/api/contact/' + contactId + '/favorite');
    }

    remove(id) {
        return this._http.delete('/api/contact/' + id);
    }

    removeTransaction(contactId, transactionId) {
        return this._http.delete('/api/contact/' + contactId + '/transaction/' + transactionId);
    }

    _makeContact(contact) {
        contact.timeline = contact.timeline.map(event => this._makeEvent(event));
        contact.sales = contact.sales.map(sale => this._makeSale(sale));
        contact.mandates = contact.mandates.map(mandate => this._makeMandate(mandate));

        contact.dateOfBirth = this._types.stringToDate(contact.dateOfBirth);
        contact.reminder = this._types.stringToDate(contact.reminder);
        contact.maxProspecting = this._types.stringToDate(contact.maxProspecting);
        contact.oldReminder = this._types.stringToDate(contact.oldReminder);
        contact.oldMaxProspecting = this._types.stringToDate(contact.oldMaxProspecting);

        return contact;
    }

    _makeEvent(event) {
        event.date = this._types.stringToDate(event.date);

        return event;
    }

    _makeMandate(mandate) {
        mandate.startDate = this._types.stringToDate(mandate.startDate);

        return mandate;
    }

    _makeSale(sale) {
        sale.certificateDate = this._types.stringToDate(sale.certificateDate);

        return sale;
    }
}

export default ContactRepository;
