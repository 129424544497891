const PREFERED_COUNTRIES = ['PF', 'FR'];
const PREFERED_CITIES = [98713, 98716, 98718, 98704, 98701];
const BLOCK_STATUS_TITLES = {project: 'En projet', ongoing: 'En cours', completed: 'Livrées', archived: 'Archivées'};

export default class ReferencesCollection {
    constructor(lodash) {
        this._references = null;
        this._lodash = lodash;
    }

    init(references) {
        this._references = references;

        return this;
    }

    isInitalized() {
        return this._references;
    }

    stepLabel(key) {
        let label = this.getLabel('saleSteps', key);

        if (!['available', 'completed', 'canceled'].includes(key)) {
            label += ' en cours';
        }

        return label;
    }

    getLabel(name, key) {
        const references = this._getReferences(name);

        return references.hasOwnProperty(key) ? references[key] : null;
    }

    mandateTypeChoices() {
        return this._extractChoices('mandateTypes');
    }

    titleChoices() {
        return this._orderChoicesByLabel(this._extractChoices('titles'));
    }

    roleChoices() {
        return this._orderChoicesByLabel(this._extractChoices('roles'));
    }

    serviceChoices() {
        return this._orderChoicesByLabel(this._extractChoices('services'));
    }

    notificationChoices() {
        return this._extractChoices('notifications');
    }

    salesRepChoices(employees, except) {
        if (!employees || !employees.length) {
            return [];
        }

        const filteredEmployees = except ? employees.filter(employee => employee.id !== except) : employees;

        let choiceGroups = this.activeSalesRepChoices(filteredEmployees);

        choiceGroups.push(
            {
                title: 'Ex-employés/inactifs',
                choices: this._orderChoicesByLabel(this._extractChoiceFromEmployees(filteredEmployees, employee => (!employee.isActive || employee.isDeleted) && ['ROLE_SALES_REP', 'ROLE_CEO'].includes(employee.mainRole)))
            }
        );

        return choiceGroups;
    }

    employee(employees, salesRepId) {
        if (!employees || !employees.length) {
            return null;
        }

        return employees.find(employee => employee.id === salesRepId);
    }

    firstSalesRepId(employees) {
        if (!employees || !employees.length) {
            return [];
        }

        const activeSalesRep = this.activeSalesRepChoices(employees);

        return activeSalesRep[0].choices[0].value;
    }

    activeSalesRepChoices(employees) {
        if (!employees || !employees.length) {
            return [];
        }

        return [
            {
                title: 'Négociateurs',
                choices: this._orderChoicesByLabel(this._extractChoiceFromEmployees(employees, employee => employee.isActive && employee.mainRole === 'ROLE_SALES_REP'))
            },
            {
                title: 'Autres',
                choices: this._orderChoicesByLabel(this._extractChoiceFromEmployees(employees, employee => employee.isActive && employee.mainRole === 'ROLE_CEO'))
            }
        ];
    }

    sourceChoices() {
        return this._extractChoices('sources');
    }

    rentBudgetChoices() {
        return this._orderChoicesByValue(this._extractChoices('rentBudgets'), (choice) => parseInt(choice.value));
    }

    furnishedChoices() {
        return this._extractChoices('furnished');
    }

    prospectRentTaxExemptionChoices() {
        return this._extractChoices('rentTaxExemptions');
    }

    buyBudgetChoices() {
        return this._orderChoicesByValue(this._extractChoices('buyBudgets'), (choice) => parseInt(choice.value));
    }

    purchaseTypeChoices() {
        return this._orderChoicesByLabel(this._extractChoices('purchaseTypes'));
    }

    ageChoices() {
        return this._orderChoicesByValue(this._extractChoices('ages'));
    }

    countryChoices() {
        if (!this._references) {
            return [];
        }

        return [
            {
                title: 'Fréquemment utilisés',
                choices: PREFERED_COUNTRIES.map(countryCode => this._makeChoice(countryCode, this.getLabel('countries', countryCode)))
            },
            {
                title: 'Tous',
                choices: this._extractChoices('countries')
            }
        ];
    }

    cityPFChoices() {
        if (!this._references) {
            return [];
        }

        const cities = this._getReferences('citiesPF').filter(cityPF => cityPF.opt_code);

        const groupedCities = this._lodash.groupBy(cities, city => city.area);
        const prefered = cities.filter(city => PREFERED_CITIES.includes(city.zip_code));

        return [
            {title: 'Fréquemment utilisés', choices: this._makePFCitiesChoices(prefered)},
            ...Object.keys(groupedCities).map(area => {
                return {
                    title: area,
                    choices: this._orderChoicesByLabel(this._makePFCitiesChoices(groupedCities[area]))
                };
            })
        ];
    }

    bankManagerChoices(contractors, except) {
        return this._contractorCategoryChoices(contractors, 'bank', except);
    }

    lawyerChoices(contractors, except) {
        return this._contractorCategoryChoices(contractors, 'lawyer', except);
    }

    saleDimensionChoices() {
        return this._extractChoices('saleDimensions');
    }

    saleMetricChoices() {
        return this._extractChoices('saleMetrics');
    }

    _contractorCategoryChoices(contractors, category, except) {
        if (!contractors) {
            return [];
        }

        return this._orderChoicesByLabel(
            contractors
                .filter(contractor => !except || contractor.id !== except)
                .filter(contractor => contractor.category === category)
                .map(contractor => this._makeChoice(contractor.id, (contractor.company ? contractor.company + ' - ' : '') + contractor.name))
        );
    }

    financingChoices() {
        return this._extractChoices('financings');
    }

    loanTypeChoices() {
        return this._extractChoices('loanTypes');
    }

    loanGuaranteeTypeChoices() {
        return this._extractChoices('loanGuaranteeTypes');
    }

    areaChoices() {
        return this._extractChoices('areas');
    }

    propertyTypeChoices() {
        return this._extractChoices('propertyTypes');
    }

    blockChoices(blocks, disableArchived = false) {
        if (!blocks) {
            return [];
        }

        return this._orderChoicesByLabel(blocks.map(block => this._makeChoice(block.id, block.name, !!block.hiddenDivisions || (disableArchived && block.status === 'archived'))));
    }

    groupedBlockChoices(blocks, statuses = ['project', 'ongoing', 'completed', 'archived']) {
        if (!blocks) {
            return [];
        }

        return statuses.map(status => {
            return {
                title: BLOCK_STATUS_TITLES[status],
                choices: this.blockChoices(blocks.filter(block => block.status === status))
            };
        });
    }

    apptTypeChoices() {
        return this._makeChoices({1: 'F1', 2: 'F2', 3: 'F3', 4: 'F4', 5: 'F5'});
    }

    imaginePropertyTypes() {
        return ['apartment', 'penthouse', 'villa', 'land', 'premises_pro', 'premises_business'];
    }

    prospectTransactionChoices() {
        return this._extractChoices('prospectTransactionTypes').concat([{label: 'NON prospect', value: 'none', disabled: false}]);
    }

    clientTransactionChoices() {
        return this._extractChoices('clientTransactionTypes');
    }

    propertyTransactionChoices() {
        return this._extractChoices('propertyTransactionTypes');
    }

    blockStatusChoices() {
        return this._extractChoices('blockStatuses');
    }

    saleStatusChoices() {
        return this._extractChoices('saleStatuses');
    }

    salePaymentsStatusChoices() {
        return this._extractChoices('saleStatuses');
    }

    saleStepsChoices() {
        return this._extractChoices('saleSteps').map(choice => {
            choice.label = this.stepLabel(choice.value);

            return choice;
        });
    }

    taxExemptionChoices() {
        return this._extractChoices('taxExemptions');
    }

    blockOptionsChoices() {
        return this._extractChoices('blockOptions');
    }

    blockPropertyOptionsChoices() {
        return this._extractChoices('blockPropertyOptions');
    }

    propertyOptionChoices() {
        return this._extractChoices('propertyOptions');
    }

    contractorCategoryChoices() {
        return this._extractChoices('contractorCategories');
    }

    cancelSaleReasonChoices() {
        return this._orderChoicesByLabel(this._extractChoices('saleCancelReasons'));
    }

    findCityPFByCode(optCode) {
        return this._getReferences('citiesPF').find(cityPF => cityPF.opt_code === parseInt(optCode));
    }

    findCityPFByZipCode(zipCode) {
        return this._getReferences('citiesPF').find(cityPF => cityPF.zip_code === zipCode);
    }

    publishings() {
        return this._getReferences('publishings');
    }

    services() {
        return this._getReferences('services');
    }

    publishingLabel(id) {
        return this._getValueFromListAndKey(this.publishings(), id);
    }

    serviceLabel(code) {
        return this._getValueFromListAndKey(this.services(), code);
    }

    mandateActionLabel(action) {
        return this._getValueFromListAndKey(this._getReferences('mandateActions'), action);
    }

    contractorCategoryLabel(category) {
        return this._getValueFromListAndKey(this._getReferences('contractorCategories'), category);
    }

    _getValueFromListAndKey(list, key) {
        if (!list || list.length <= 0) {
            return null;
        }

        return list.hasOwnProperty(key) ? list[key] : null;
    }

    _extractChoiceFromEmployees(employees, filter) {
        return employees
            .filter(filter)
            .map(employee => this._makeChoice(employee.id, employee.firstname + ' ' + employee.lastname.charAt(0) + '.'));
    }

    _extractChoices(name) {
        return this._makeChoices(this._getReferences(name));
    }

    _makeChoices(map) {
        return Object.keys(map).map(key => this._makeChoice(key, map[key]));
    }

    _orderChoicesByLabel(choices) {
        return this._lodash.orderBy(choices, [choice => choice.label], ['asc']);
    }

    _orderChoicesByValue(choices, sortFunction) {
        return this._lodash.orderBy(choices, [choice => sortFunction ? sortFunction(choice) : choice.value], ['asc']);
    }

    _makePFCitiesChoices(citiesPF) {
        return citiesPF.map(cityPF => this._makeChoice(cityPF.opt_code, cityPF.island + ' - ' + cityPF.city + ' (' + cityPF.zip_code + ')'));
    }

    _getReferences(name) {
        if (!this._references || !this._references.hasOwnProperty(name)) {
            return [];
        }

        return this._references[name];
    }

    _makeChoice(value, label, disabled = false) {
        label = label || value;

        if (value && label) {
            return {value, label, disabled};
        }

        return null;
    }
}
