var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      attrs: { title: "Biens agence" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "search", icon: "search" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("search")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "list", icon: "filter_list" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("list")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "map", icon: "map" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("map")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("propertyAdd")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("properties-list", {
        attrs: { mode: _vm.mode, prefilter: "agency" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ property }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "mdc-list-item",
                    on: {
                      click: function ($event) {
                        return _vm.gotoProperty(property)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "mdc-list-item__text" }, [
                      _c(
                        "span",
                        { staticClass: "mdc-list-item__primary-text" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(property.infos) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "mdc-list-item__secondary-text" },
                        [
                          _vm._v(
                            "\n                        Référence " +
                              _vm._s(property.reference) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mdc-list-item__meta" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(property.status) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }