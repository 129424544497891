var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mandate",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.mandate
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: {
                      disabled: _vm.mandate.sale || _vm.hasLeases,
                      icon: "delete",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showRemoveConfirmation = true
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.tabs.length > 1
        ? _c("imagine-tab-bar", {
            attrs: { tabs: _vm.tabs },
            on: {
              tab: function ($event) {
                _vm.activeTabId = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "infos"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c(
                "form",
                {
                  staticClass: "imagine-form",
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.mandateId
                    ? _c("imagine-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          "\n                    Avant de commencer à saisir un nouveau mandat, il est nécessaire de\n                    créer ou de vérifier "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.redirectTo("propertyAdd")
                              },
                            },
                          },
                          [_vm._v("le(s) bien(s)")]
                        ),
                        _vm._v(" et\n                    "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.redirectTo("contactAdd")
                              },
                            },
                          },
                          [_vm._v("le(s) propriétaire(s)")]
                        ),
                        _vm._v(".\n                "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors.global
                    ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row imagine-form__row--limited",
                    },
                    [
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.mandateTypeChoices(),
                          required: true,
                          label: "Type",
                          name: "type",
                        },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Date de signature",
                          name: "date",
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.salesRepChoices,
                          label: "Négociateur",
                          name: "salesrep",
                        },
                        model: {
                          value: _vm.salesRep,
                          callback: function ($$v) {
                            _vm.salesRep = $$v
                          },
                          expression: "salesRep",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row__field mandate__isexclusive",
                        },
                        [
                          _c(
                            "imagine-switch",
                            {
                              model: {
                                value: _vm.isExclusive,
                                callback: function ($$v) {
                                  _vm.isExclusive = $$v
                                },
                                expression: "isExclusive",
                              },
                            },
                            [_c("span", [_vm._v("Exclusivité ?")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row imagine-form__row--limited",
                    },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Numéro d'ordre",
                          name: "registryNumber",
                          violation: _vm.errors.registryNumber,
                        },
                        model: {
                          value: _vm.registryNumber,
                          callback: function ($$v) {
                            _vm.registryNumber = $$v
                          },
                          expression: "registryNumber",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Date de début",
                          name: "startDate",
                          "min-date": _vm.minMandateDate,
                        },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Date de fin",
                          help: _vm.legalEndDate
                            ? "Légalement " + _vm.formatDate(_vm.legalEndDate)
                            : "",
                          name: "endDate",
                        },
                        model: {
                          value: _vm.endDate,
                          callback: function ($$v) {
                            _vm.endDate = $$v
                          },
                          expression: "endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.ownerSlots, function (ownerSlot, slot) {
                    return _vm.nbOwners >= slot
                      ? _c(
                          "div",
                          { staticClass: "imagine-form__row" },
                          [
                            _c("imagine-contact-autocomplete", {
                              staticClass: "imagine-form__row__field",
                              attrs: {
                                filter: _vm.canContactBeOwner,
                                label:
                                  slot > 0
                                    ? "Autre propriétaire"
                                    : "Propriétaire" +
                                      (_vm.nbOwners >= 1 ? " principal" : ""),
                                required: slot <= 0,
                                name: "owner",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.removeOwnerSlotHoles()
                                },
                              },
                              model: {
                                value: ownerSlot.owner,
                                callback: function ($$v) {
                                  _vm.$set(ownerSlot, "owner", $$v)
                                },
                                expression: "ownerSlot.owner",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.propertySlots, function (propertySlot, slot) {
                    return _vm.nbProperties >= slot
                      ? _c(
                          "div",
                          { staticClass: "imagine-form__row" },
                          [
                            _c("imagine-property-autocomplete", {
                              staticClass: "imagine-form__row__field",
                              attrs: {
                                filter: _vm.canPropertyReceiveMandate,
                                label: slot > 0 ? "Autre bien" : "Bien",
                                required: slot <= 0,
                                violation:
                                  slot <= 0 ? _vm.errors.properties : "",
                                name: "property",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.removePropertySlotHoles()
                                },
                              },
                              model: {
                                value: propertySlot.property,
                                callback: function ($$v) {
                                  _vm.$set(propertySlot, "property", $$v)
                                },
                                expression: "propertySlot.property",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-textarea", {
                        staticClass: "imagine-form__row__field",
                        attrs: { label: "Observations", name: "comment" },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__actions" },
                    [
                      _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.back()
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _vm._v(" "),
                      _c(
                        "imagine-button",
                        { attrs: { primary: true, type: "submit" } },
                        [
                          _vm._v(
                            "\n                        Sauvegarder\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.mandate && _vm.activeTabId === "leases"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "mandate-leases" }, [
                _vm.hasLeases
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.mandate.properties, function (property) {
                          return [
                            _c("h3", { staticClass: "mandate-leases__title" }, [
                              _vm._v(_vm._s(property.infos)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "imagine-table-container" },
                              [
                                _vm.leases(property).length > 0
                                  ? _c(
                                      "table",
                                      { staticClass: "imagine-table" },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            {
                                              staticClass: "imagine-table__row",
                                            },
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Locataire(s)\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Date d'entrée\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Date de sortie\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--rent",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Loyer charges comprises\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.leases(property),
                                            function (lease) {
                                              return _c(
                                                "tr",
                                                {
                                                  staticClass:
                                                    "imagine-table__row mandate-leases__tenant",
                                                  class: {
                                                    "imagine-table__row--disabled":
                                                      lease.end &&
                                                      lease.end <
                                                        _vm.$container.types.today(),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "imagine-table__row__cell imagine-table__row__cell--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            lease.tenants
                                                              .map(
                                                                (tenant) =>
                                                                  tenant.shortName
                                                              )
                                                              .join(", ")
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "imagine-table__row__cell imagine-table__row__cell--date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              lease.start
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "imagine-table__row__cell imagine-table__row__cell--date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              lease.end
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "imagine-table__row__cell imagine-table__row__cell--rent",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.formatMoney(
                                                              lease.rent
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "mandate-leases__none" },
                                      [
                                        _vm._v(
                                          "\n                                Aucun locataire pour ce bien.\n                            "
                                        ),
                                      ]
                                    ),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "mandate-leases__none" }, [
                      _vm._v(
                        "\n                    Aucun bail.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "sale"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "mandate-sale" }, [
                _vm.mandate && _vm.mandate.sales.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.mandate.properties, function (property) {
                          return [
                            _c("h3", { staticClass: "mandate-sale__title" }, [
                              _vm._v(_vm._s(property.infos)),
                            ]),
                            _vm._v(" "),
                            _vm.sale(property)
                              ? _c("p", [
                                  _vm._v(
                                    "\n                            Acheteur : " +
                                      _vm._s(
                                        _vm.sale(property).buyer.shortName
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _c("p", { staticClass: "mandate-sale__none" }, [
                                  _vm._v(
                                    "\n                            Aucune vente pour ce bien.\n                        "
                                  ),
                                ]),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "mandate-sale__none" }, [
                      _vm._v(
                        "\n                    Aucune vente.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showRemoveConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: {
                act: function ($event) {
                  return _vm.removeMandate()
                },
                close: function ($event) {
                  _vm.showRemoveConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir supprimer ce mandat ?"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }