import Dinero from 'dinero.js';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment-timezone/moment-timezone';

Dinero.globalLocale = 'fr-PF';
moment.tz.add('Pacific/Tahiti|LMT -10|9W.g a0|01|-2joe1.I|18e4');

class Types {
    stringToDate(serializedDate) {
        if (!serializedDate) {
            return null;
        }

        if (serializedDate.hasOwnProperty('date')) {
            return moment.utc(serializedDate.date);
        }

        return moment.utc(serializedDate, 'DD/MM/YYYY');
    }

    jsToDate(jsDate) {
        return moment.utc(jsDate);
    }

    today() {
        return moment();
    }

    cloneDate(date) {
        return moment(date);
    }

    isToday(date) {
        return date.isSame(moment(), 'day');
    }

    isInFuture(date) {
        return date.isAfter(moment(), 'day');
    }

    daysAgo(nbDays) {
        return moment().subtract(nbDays, 'd');
    }

    toTAHT(utcDate) {
        if (!utcDate) {
            return null;
        }

        return utcDate.clone().tz('Pacific/Tahiti');
    }

    formatNumber(number) {
        if (!number) {
            return 0;
        }

        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this._space()).replace('.', ',');
    }

    formatDateTime(date) {
        if (!date) {
            return null;
        }

        return date.format('DD/MM/YYYY HH:mm');
    }

    formatDate(date) {
        if (!date) {
            return null;
        }

        return date.format('DD/MM/YYYY');
    }

    formatMonthYear(date) {
        if (!date) {
            return null;
        }

        return date.format('MMM YYYY', 'fr');
    }

    formatDateShort(date) {
        if (!date) {
            return null;
        }

        return date.format('DD/MM');
    }

    formatTime(date) {
        if (!date) {
            return null;
        }

        return date.format(' HH:mm');
    }

    queryDate(date) {
        if (!date) {
            return null;
        }

        return date.format('X');
    }

    stringToMoney(serializedMoney) {
        if (!serializedMoney) {
            return null;
        }

        serializedMoney.precision = 0;
        serializedMoney.amount = parseInt(serializedMoney.amount);
        serializedMoney.locale = 'fr-PF';

        return Dinero(serializedMoney);
    }

    makeMoney(amount) {
        return this.stringToMoney({amount: amount ? parseInt(amount.replace(this._space(), '')) : 0, currency: 'XPF'});
    }

    formatMoneyAmount(money) {
        if (!money) {
            return null;
        }

        return this.formatNumber(money.getAmount());
    }

    formatMoney(money) {
        if (!money) {
            return null;
        }

        return this.formatMoneyAmount(money) + this._space() + money.getCurrency();
    }

    formatKilosMoney(money) {
        if (!money) {
            return null;
        }

        const amount = parseInt(money.getAmount());
        const kilos = amount / 1000;

        if (amount % 1000 !== 0) {
            return this.formatMoney(money);
        }

        return this.formatNumber(amount % 1000 < 100 ? Math.floor(kilos) : kilos.toFixed(1)) + 'K';
    }

    formatMillionsMoney(money) {
        if (!money) {
            return null;
        }

        const amount = parseInt(money.getAmount());
        const millions = amount / 1000000;

        return this.formatNumber(amount % 1000000 < 100000 ? Math.floor(millions) : millions.toFixed(1)) + 'M';
    }

    _space() {
        return String.fromCharCode(160);
    }
}

export default Types;
