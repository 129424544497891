import container from '../container';

const repo = container.repos.property;

const MAX_DISPLAYED = 50;

export default {
    namespaced: true,
    state: {
        displayed: [],
        maxDisplayed: MAX_DISPLAYED,
        nbTotal: 0,
        nbFiltered: 0,
        markers: [],
        query: null,
        transaction: null,
        type: null,
        block: null,
        nbRooms: null,
        available: null,
        mode: 'search',
        published: [],
        lastTab: 'infos',
        redirectAfterSave: null,
        lastSaved: null,
        sort: 'updated_desc',
        prefilter: null
    },
    getters: {
        filters: state => {
            return {
                prefilter: state.prefilter,
                transaction: state.transaction,
                type: state.type,
                block: state.block,
                nbRooms: state.nbRooms,
                available: state.available
            };
        }
    },
    mutations: {
        setLastTab(state, tab) {
            state.lastTab = tab;
        },
        resetLastTab(state) {
            state.lastTab = 'infos';
        },
        setRedirectAfterSave(state, route) {
            state.redirectAfterSave = route;
        },
        clearRedirectAfterSave(state) {
            state.redirectAfterSave = null;
        },
        setLastSaved(state, property) {
            state.lastSaved = property;
        },
        setQuery(state, query) {
            state.query = query;
        },
        setTransaction(state, transaction) {
            state.transaction = transaction;
        },
        setType(state, type) {
            state.type = type;
        },
        setBlock(state, block) {
            state.block = block;
        },
        setNbRooms(state, block) {
            state.nbRooms = block;
        },
        setAvailable(state, available) {
            state.available = available;
        },
        setSort(state, sort) {
            state.sort = sort;
        },
        setPrefilter(state, prefilter) {
            state.prefilter = prefilter;
        },
        changeMode(state, mode) {
            const origin = state.mode;

            state.mode = mode;

            if (mode === 'search') {
                state.transaction = null;
                state.type = null;
                state.block = null;
                state.nbRooms = null;
                state.available = null;
            }

            if (origin === 'search') {
                state.query = null;
            }
        },
    },
    actions: {
        reloadMarkers({state, getters}) {
            return repo.markers({filters: getters.filters})
                .then(markers => state.markers = markers);
        },
        reload({state, getters}) {
            return repo.all({limit: state.maxDisplayed, query: state.query, filters: getters.filters, sort: state.sort})
                .then(collection => {
                    state.nbTotal = collection.nbTotal;
                    state.nbFiltered = collection.nbFiltered;
                    state.displayed = collection.entities;
                });
        },
        displayMore({state, dispatch}) {
            state.maxDisplayed += state.maxDisplayed;

            return dispatch('reload');
        },
        touch({state, dispatch}) {
            if (!state.displayed || state.displayed.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        query({state, dispatch}, query) {
            return repo.all({limit: 200, query: query})
                .then(collection => collection.entities);
        },
        one(context, id) {
            return repo.one(id);
        },
        save(context, data) {
            return repo.save(data);
        },
        addEvent(context, {propertyId, description}) {
            return repo.addEvent(propertyId, description);
        },
        remove(context, id) {
            return repo.remove(id);
        },
        duplicate(context, id) {
            return repo.duplicate(id);
        },
        reloadPublished({state}) {
            return repo.published().then(published => state.published = published);
        },
        publish(context, id) {
            return repo.publish(id);
        },
        unpublish(context, id) {
            return repo.unpublish(id);
        },
        book(context, {id, ownerId, type}) {
            return repo.book(id, ownerId, type);
        }
    }
};
