var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "imagine-layout" },
    [
      _c("transition", { attrs: { name: "side" } }, [
        _c(
          "aside",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMobileSidebar,
                expression: "showMobileSidebar",
              },
            ],
            staticClass: "mdc-drawer imagine-layout__sidebar",
          },
          [
            _c(
              "div",
              { staticClass: "mdc-drawer__header" },
              [
                _c("imagine-logo", {
                  staticClass: "imagine-layout__sidebar__logo",
                  on: {
                    click: function ($event) {
                      return _vm.redirectToHome()
                    },
                  },
                }),
                _vm._v(" "),
                _vm.user
                  ? _c(
                      "div",
                      { staticClass: "imagine-layout__sidebar__user-infos" },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.user.image,
                              expression: "user.image",
                            },
                          ],
                          staticClass:
                            "imagine-layout__sidebar__user-infos__picture",
                          attrs: { src: _vm.user.image },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "mdc-drawer__title imagine-layout__sidebar__user-infos__name",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.user.firstname) +
                                  "\n                            " +
                                  _vm._s(_vm.user.lastname) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticClass:
                                "mdc-drawer__subtitle imagine-layout__sidebar__user-infos__email",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.user.email) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "mdc-drawer__content imagine-layout__sidebar__menu",
              },
              [
                _c(
                  "nav",
                  {
                    staticClass: "mdc-list imagine-layout__sidebar__menu__list",
                  },
                  [
                    _vm.isGranted(["ROLE_CEO"])
                      ? [
                          _c("imagine-menu-item", {
                            attrs: {
                              icon: "history",
                              label: "Activité",
                              route: "activities",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-menu-item", {
                            attrs: {
                              icon: "assignment_ind",
                              label: "Employés",
                              route: "employees",
                            },
                          }),
                          _vm._v(" "),
                          _c("hr", { staticClass: "mdc-list-divider" }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("imagine-menu-item", {
                      attrs: {
                        icon: "contacts",
                        label: "Contacts",
                        route: "contacts",
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-menu-item", {
                      attrs: {
                        icon: "how_to_reg",
                        label: "Prestataires",
                        route: "contractors",
                      },
                    }),
                    _vm._v(" "),
                    _vm.isGranted([
                      "ROLE_CEO",
                      "ROLE_COMMUNICATION",
                      "ROLE_SALES_REP",
                    ])
                      ? _c("imagine-menu-item", {
                          attrs: {
                            icon: "send",
                            label: "Publipostages",
                            route: "mailings",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr", { staticClass: "mdc-list-divider" }),
                    _vm._v(" "),
                    _c("imagine-menu-item", {
                      attrs: {
                        icon: "business",
                        label: "Résidences",
                        route: "blocks",
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-menu-item", {
                      attrs: {
                        icon: "home_work",
                        label: "Biens",
                        route: "properties",
                      },
                    }),
                    _vm._v(" "),
                    _c("hr", { staticClass: "mdc-list-divider" }),
                    _vm._v(" "),
                    _vm.isGranted(["ROLE_CEO", "ROLE_SALES"])
                      ? [
                          _c(
                            "h6",
                            { staticClass: "mdc-list-group__subheader" },
                            [_vm._v("Gestion des ventes")]
                          ),
                          _vm._v(" "),
                          _c("imagine-menu-item", {
                            attrs: {
                              icon: "card_membership",
                              label: "Admninistration",
                              route: "sales",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-menu-item", {
                            attrs: {
                              icon: "av_timer",
                              label: "Appels de fonds",
                              route: "salesPayments",
                            },
                          }),
                          _vm._v(" "),
                          _c("hr", { staticClass: "mdc-list-divider" }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMandatesGranted()
                      ? [
                          _c(
                            "h6",
                            { staticClass: "mdc-list-group__subheader" },
                            [_vm._v("Gestion agence")]
                          ),
                          _vm._v(" "),
                          _c("imagine-menu-item", {
                            attrs: {
                              icon: "home_work",
                              route: "agencyProperties",
                              label: "Biens",
                            },
                          }),
                          _vm._v(" "),
                          _vm.isGranted([
                            "ROLE_CEO",
                            "ROLE_COMMUNICATION",
                            "ROLE_SALES_REP",
                          ])
                            ? _c("imagine-menu-item", {
                                attrs: {
                                  icon: "publish",
                                  label: "Annonces",
                                  route: "publishing",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("imagine-menu-item", {
                            attrs: {
                              icon: "assignment",
                              route: "mandates",
                              label: "Mandats",
                            },
                          }),
                          _vm._v(" "),
                          _c("hr", { staticClass: "mdc-list-divider" }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("imagine-menu-item", {
                      attrs: { icon: "calculate", label: "Simulateur emprunt" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.showCalculator = true
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-menu-item", {
                      attrs: { icon: "exit_to_app", label: "Se déconnecter" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imagine-layout__sidebar__menu__footer" },
                  [
                    _vm._v(
                      "\n                    © " +
                        _vm._s(_vm.year) +
                        " - tous droits réservés - " +
                        _vm._s(_vm.version) +
                        "\n                "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mdc-drawer-app-content" }, [
        _c(
          "header",
          {
            staticClass:
              "mdc-top-app-bar mdc-top-app-bar--fixed imagine-layout__header",
            class: {
              "imagine-layout__header--with-sidebar": _vm.showMobileSidebar,
            },
          },
          [
            _c("div", { staticClass: "mdc-top-app-bar__row" }, [
              _c(
                "section",
                {
                  staticClass:
                    "mdc-top-app-bar__section mdc-top-app-bar__section--align-start",
                },
                [
                  !_vm.inner
                    ? _c("imagine-icon-button", {
                        staticClass:
                          "imagine-layout__header__toggle mdc-top-app-bar__navigation-icon",
                        attrs: {
                          icon: _vm.showMobileSidebar ? "menu_open" : "menu",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showMobileSidebar = !_vm.showMobileSidebar
                          },
                        },
                      })
                    : _c("imagine-icon-button", {
                        staticClass: "mdc-top-app-bar__navigation-icon",
                        attrs: { icon: "arrow_back" },
                        on: {
                          click: function ($event) {
                            return _vm.goBack()
                          },
                        },
                      }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showMobileSidebar,
                          expression: "!showMobileSidebar",
                        },
                      ],
                      staticClass:
                        "mdc-top-app-bar__navigation-icon imagine-layout__header__brand",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.redirectToHome.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            height: "36",
                            version: "1.1",
                            viewBox: "0, 0, 48, 48",
                            width: "36",
                            x: "0",
                            xmlns: "http://www.w3.org/2000/svg",
                            y: "0",
                          },
                        },
                        [
                          _c("g", { attrs: { id: "logo-mini" } }, [
                            _c("path", {
                              attrs: {
                                d: "M28.137,32.593 L28.115,32.604 C28.666,33.423 28.98,34.383 28.98,35.378 C28.98,38.125 26.746,40.359 24,40.359 C21.254,40.359 19.02,38.125 19.02,35.378 C19.02,32.632 21.254,30.399 24,30.399 C31.589,30.399 37.763,24.225 37.763,16.635 C37.763,9.047 31.589,2.872 24,2.872 C16.411,2.872 10.237,9.047 10.237,16.635 C10.237,20.472 11.822,24.028 14.542,26.6 C15.648,25.4 16.984,24.423 18.474,23.713 C16.294,22.014 15.004,19.438 15.004,16.635 C15.004,11.675 19.04,7.641 24,7.641 C28.96,7.641 32.996,11.675 32.996,16.635 C32.996,21.595 28.96,25.632 24,25.632 C18.626,25.632 14.253,30.003 14.253,35.378 C14.253,40.753 18.626,45.127 24,45.127 C29.375,45.127 33.747,40.753 33.747,35.378 C33.747,33.741 33.321,32.141 32.546,30.719 C31.192,31.545 29.711,32.185 28.137,32.593",
                                fill: "#FFFFFF",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-top-app-bar__title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showMobileSidebar,
                      expression: "!showMobileSidebar",
                    },
                  ],
                  staticClass:
                    "mdc-top-app-bar__section mdc-top-app-bar__section--align-end",
                },
                [_vm._t("actions")],
                2
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-top-app-bar--fixed-adjust" }, [
          _c(
            "div",
            { staticClass: "imagine-layout__workspace" },
            [
              _vm.isLoading ? _c("imagine-loader") : _vm._e(),
              _vm._v(" "),
              !_vm.loadingMessage
                ? [
                    _vm._t("default"),
                    _vm._v(" "),
                    _c("imagine-scroll-top"),
                    _vm._v(" "),
                    _c("imagine-message"),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.showCalculator
        ? _c("imagine-calculator", {
            on: {
              close: function ($event) {
                _vm.showCalculator = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }