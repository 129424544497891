<template>
    <div class="imagine-layout">
        <transition name="side">
            <aside class="mdc-drawer imagine-layout__sidebar" v-show="showMobileSidebar">
                <div class="mdc-drawer__header">
                    <imagine-logo @click="redirectToHome()"
                                  class="imagine-layout__sidebar__logo"/>

                    <div class="imagine-layout__sidebar__user-infos" v-if="user">
                        <img :src="user.image"
                             class="imagine-layout__sidebar__user-infos__picture"
                             v-show="user.image"/>
                        <div>
                            <h3 class="mdc-drawer__title imagine-layout__sidebar__user-infos__name">
                                {{ user.firstname }}
                                {{ user.lastname }}
                            </h3>
                            <h6 class="mdc-drawer__subtitle imagine-layout__sidebar__user-infos__email">
                                {{ user.email }}
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="mdc-drawer__content imagine-layout__sidebar__menu">
                    <nav class="mdc-list imagine-layout__sidebar__menu__list">
                        <template v-if="isGranted(['ROLE_CEO'])">
                            <imagine-menu-item icon="history"
                                               label="Activité"
                                               route="activities"/>
                            <imagine-menu-item icon="assignment_ind"
                                               label="Employés"
                                               route="employees"/>
                            <hr class="mdc-list-divider">
                        </template>
                        <imagine-menu-item icon="contacts"
                                           label="Contacts"
                                           route="contacts"/>
                        <imagine-menu-item icon="how_to_reg"
                                           label="Prestataires"
                                           route="contractors"/>
                        <imagine-menu-item icon="send"
                                           label="Publipostages"
                                           route="mailings"
                                           v-if="isGranted(['ROLE_CEO', 'ROLE_COMMUNICATION', 'ROLE_SALES_REP'])"/>
                        <hr class="mdc-list-divider">
                        <imagine-menu-item icon="business"
                                           label="Résidences"
                                           route="blocks"/>
                        <imagine-menu-item icon="home_work"
                                           label="Biens"
                                           route="properties"/>
                        <hr class="mdc-list-divider">
                        <template v-if="isGranted(['ROLE_CEO', 'ROLE_SALES'])">
                            <h6 class="mdc-list-group__subheader">Gestion des ventes</h6>
                            <imagine-menu-item icon="card_membership"
                                               label="Admninistration"
                                               route="sales"/>
                            <imagine-menu-item icon="av_timer"
                                               label="Appels de fonds"
                                               route="salesPayments"/>
                            <hr class="mdc-list-divider">
                        </template>
                        <template v-if="isMandatesGranted()">
                            <h6 class="mdc-list-group__subheader">Gestion agence</h6>
                            <imagine-menu-item icon="home_work"
                                               route="agencyProperties"
                                               label="Biens"/>
                            <imagine-menu-item icon="publish"
                                               label="Annonces"
                                               route="publishing"
                                               v-if="isGranted(['ROLE_CEO', 'ROLE_COMMUNICATION', 'ROLE_SALES_REP'])"/>
                            <imagine-menu-item icon="assignment"
                                               route="mandates"
                                               label="Mandats"/>
                            <hr class="mdc-list-divider">
                        </template>
                        <imagine-menu-item @click.prevent="showCalculator = true"
                                           icon="calculate"
                                           label="Simulateur emprunt"/>
                        <imagine-menu-item @click.prevent="logout"
                                           icon="exit_to_app"
                                           label="Se déconnecter"/>
                    </nav>
                    <div class="imagine-layout__sidebar__menu__footer">
                        &copy; {{ year }} - tous droits réservés - {{ version }}
                    </div>
                </div>
            </aside>
        </transition>

        <div class="mdc-drawer-app-content">
            <header :class="{'imagine-layout__header--with-sidebar': showMobileSidebar}"
                    class="mdc-top-app-bar mdc-top-app-bar--fixed imagine-layout__header">
                <div class="mdc-top-app-bar__row">
                    <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
                        <imagine-icon-button @click="showMobileSidebar = !showMobileSidebar"
                                             class="imagine-layout__header__toggle mdc-top-app-bar__navigation-icon"
                                             :icon="showMobileSidebar ? 'menu_open' : 'menu'"
                                             v-if="!inner"/>
                        <imagine-icon-button @click="goBack()"
                                             class="mdc-top-app-bar__navigation-icon"
                                             icon="arrow_back"
                                             v-else/>
                        <a @click.prevent="redirectToHome"
                           class="mdc-top-app-bar__navigation-icon imagine-layout__header__brand"
                           href="#"
                           v-show="!showMobileSidebar">
                            <svg height="36" version="1.1" viewBox="0, 0, 48, 48" width="36" x="0"
                                 xmlns="http://www.w3.org/2000/svg"
                                 y="0">
                                <g id="logo-mini">
                                    <path
                                        d="M28.137,32.593 L28.115,32.604 C28.666,33.423 28.98,34.383 28.98,35.378 C28.98,38.125 26.746,40.359 24,40.359 C21.254,40.359 19.02,38.125 19.02,35.378 C19.02,32.632 21.254,30.399 24,30.399 C31.589,30.399 37.763,24.225 37.763,16.635 C37.763,9.047 31.589,2.872 24,2.872 C16.411,2.872 10.237,9.047 10.237,16.635 C10.237,20.472 11.822,24.028 14.542,26.6 C15.648,25.4 16.984,24.423 18.474,23.713 C16.294,22.014 15.004,19.438 15.004,16.635 C15.004,11.675 19.04,7.641 24,7.641 C28.96,7.641 32.996,11.675 32.996,16.635 C32.996,21.595 28.96,25.632 24,25.632 C18.626,25.632 14.253,30.003 14.253,35.378 C14.253,40.753 18.626,45.127 24,45.127 C29.375,45.127 33.747,40.753 33.747,35.378 C33.747,33.741 33.321,32.141 32.546,30.719 C31.192,31.545 29.711,32.185 28.137,32.593"
                                        fill="#FFFFFF"/>
                                </g>
                            </svg>
                        </a>
                        <span class="mdc-top-app-bar__title">{{ title }}</span>
                    </section>
                    <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
                             v-show="!showMobileSidebar">
                        <slot name="actions"/>
                    </section>
                </div>
            </header>

            <div class="mdc-top-app-bar--fixed-adjust">
                <div class="imagine-layout__workspace">
                    <imagine-loader v-if="isLoading"/>
                    <template v-if="!loadingMessage">
                        <slot name="default"/>
                        <imagine-scroll-top/>
                        <imagine-message/>
                    </template>
                </div>
            </div>
        </div>

        <imagine-calculator @close="showCalculator = false" v-if="showCalculator"/>
    </div>
</template>

<script>
import {MDCList} from '@material/list/index';
import ImagineMenuItem from './ImagineMenuItem.vue';
import ImagineLogo from './ImagineLogo.vue';
import ImagineModal from './ImagineModal.vue';
import ImagineIconButton from './ImagineIconButton.vue';
import ImagineLoader from './ImagineLoader.vue';
import ImagineScrollTop from './ImagineScrollTop.vue';
import ImagineMessage from './ImagineMessage.vue';
import ImagineCalculator from './ImagineCalculator.vue';
import {mapGetters, mapState} from 'vuex';

export default {
    props: {
        title: {default: ''},
        inner: {default: false},
        backRoute: {default: null}
    },
    components: {
        ImagineCalculator,
        ImagineScrollTop,
        ImagineMenuItem,
        ImagineLogo,
        ImagineModal,
        ImagineIconButton,
        ImagineLoader,
        ImagineMessage
    },
    computed: {
        ...mapGetters(['loadingMessage', 'isLoading']),
        ...mapState('user', {user: state => state.current}),
        ...mapState(['version']),
        year() {
            return new Date().getFullYear();
        }
    },
    data() {
        return {
            showMobileSidebar: false,
            showCalculator: false,
            _list: null
        }
    },
    created() {
        if (!this.version) {
            this.$store.dispatch('reloadReferences');
        }
    },
    mounted() {
        this._list = MDCList.attachTo(this.$el.querySelector('.mdc-list'));
        this._list.wrapFocus = true;
    },
    beforeDestroy() {
        this._list.destroy();
        this._list = null;
    },
    methods: {
        logout() {
            this.load();
            this.$store.dispatch('user/logout')
                .then(() => this.redirectTo('login'))
                .finally(this.unload);
        },
        goBack() {
            return this.backRoute ? this.$router.push(this.backRoute) : this.back();
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/drawer/mdc-drawer';
@import '~@material/list/mdc-list';
@import '~@material/top-app-bar/mdc-top-app-bar';
@import '~@material/icon-button/mdc-icon-button';

@media (min-width: map-get($imagine-breakpoints, 'desktop')) {
    .mdc-drawer {
        display: flex !important;
    }
}

@media (min-width: map-get($imagine-breakpoints, 'desktop')) {
    .imagine-layout__header__toggle {
        display: none;
    }
}

.imagine-layout {
    display: flex;
    height: 100vh;

    &__user-menu {
        position: fixed;
        top: 64px;
        right: 1rem;
        z-index: 1;
    }

    .mdc-drawer-app-content {
        min-width: 300px;
        width: 100%;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            left: 256px;
            width: calc(100% - 256px);
        }
    }

    .mdc-top-app-bar {
        left: 0;
        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            left: 256px;
        }

        &.imagine-layout__header--with-sidebar {
            left: 256px;
        }

        right: 0;
        width: auto;
        z-index: 10;
    }

    @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
        .mdc-top-app-bar__section {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .mdc-top-app-bar__title {
        padding-left: 0;
    }

    .mdc-menu {
        background-color: #ffffff;
    }

    .imagine-logo {
        margin-bottom: 0;
    }

    &__header {
        &__brand {
            @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                display: none;
            }

            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__sidebar {
        position: fixed;
        z-index: 100;

        &__logo {
            cursor: pointer;
        }

        &__user-infos {
            margin: 1rem 0 .5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__name {
                &:before, &:after {
                    content: none;
                }

                margin: 0;
            }

            &__email {
                &:before, &:after {
                    content: none;
                }

                margin: 0;
                word-break: break-all;
            }

            &__picture {
                border-radius: 18px;
                width: 36px;
                height: 36px;
                margin-right: .5rem;
            }
        }

        &__menu {
            display: flex;
            flex-direction: column;

            &__list {
                flex: 1;

                a, a:not(.md-button) {
                    color: #000000;
                }
            }

            &__footer {
                font-size: .8rem;
                padding: .5rem;
            }
        }
    }

    &__workspace {
        position: relative;
        padding-bottom: 4rem;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            .imagine-loader {
                left: 256px;
                width: calc(100% - 256px);
            }
        }
    }
}
</style>
