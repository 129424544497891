class MandateRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    registry(type) {
        return this._http.get('/api/mandate/registry?type=' + type)
            .then(registry => this._makeRegistry(registry));
    }

    all() {
        return this._http.get('/api/mandate')
            .then(mandates => mandates.map((mandate) => this._makeMandateOverview(mandate)));
    }

    one(id) {
        return this._http.get('/api/mandate/' + id)
            .then(mandateDetail => this._makeMandateDetail(mandateDetail));
    }

    save(data) {
        return this._http.post('/api/mandate' + (data.id ? '/' + data.id : ''), data)
            .then(mandateDetail => this._makeMandateDetail(mandateDetail));
    }

    remove(id) {
        return this._http.delete('/api/mandate/' + id);
    }

    _makeRegistry(registry) {
        registry.lastDate = this._types.stringToDate(registry.lastDate);

        return registry;
    }

    _makeMandateOverview(mandate) {
        mandate.startDate = this._types.stringToDate(mandate.startDate);
        mandate.endDate = this._types.stringToDate(mandate.endDate);
        mandate.legalEndDate = this._types.stringToDate(mandate.legalEndDate);

        return mandate;
    }

    _makeMandateDetail(mandate) {
        mandate.date = this._types.stringToDate(mandate.date);
        mandate.startDate = this._types.stringToDate(mandate.startDate);
        mandate.endDate = this._types.stringToDate(mandate.endDate);
        mandate.legalEndDate = this._types.stringToDate(mandate.legalEndDate);
        mandate.leases = mandate.leases.map(lease => this._makeLease(lease));

        return mandate;
    }

    _makeLease(lease) {
        if (!lease) {
            return null;
        }

        lease.start = this._types.stringToDate(lease.start);
        lease.end = this._types.stringToDate(lease.end);
        lease.rent = this._types.stringToMoney(lease.rent);

        return lease;
    }
}

export default MandateRepository;
